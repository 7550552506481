var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container global-list-main-container"},[_c('b-field',{staticClass:"global-lists-controls",attrs:{"grouped":"","group-multiline":""}},[_c('div',{staticClass:"control"},[(
          _vm.warehouses.length === 0 ||
          _vm.priceLists.length === 0 ||
          _vm.clients.length === 0
        )?_c('b-tooltip',{attrs:{"label":"Primero debes agregar uno o varios artículos, listas de precios, clientes y almacenes al sistema o esperar a que el sistema cargue estos datos","multilined":""}},[_c('b-button',{attrs:{"type":"is-primary","disabled":""}},[_vm._v("Agregar venta")])],1):_vm._e(),(
          _vm.warehouses.length > 0 && _vm.priceLists.length > 0 && _vm.clients.length > 0
        )?_c('b-button',{attrs:{"type":"is-primary"},on:{"click":function($event){return _vm.openAddSale()}}},[_vm._v("Agregar venta")]):_vm._e()],1),_c('div',{staticClass:"control special-margin"},[(
          _vm.companyPaymentPlanModules &&
          _vm.companyPaymentPlanModules.main.exportSalesArticles === 'S' &&
          _vm.permissions &&
          _vm.permissions.main.exportSalesArticles === 'S'
        )?_c('b-button',{attrs:{"type":"is-primary"},on:{"click":function($event){return _vm.startArticlesExport()}}},[_vm._v("Exportar artículos en ventas")]):_vm._e()],1),_c('div',{staticClass:"control"},[_c('b-button',{attrs:{"type":"is-primary","loading":_vm.refreshLoading},on:{"click":function($event){return _vm.reloadInformation()}}},[_vm._v("Refrescar información")])],1)]),_c('div',{staticClass:"global-lists-search"},[_c('div',{staticClass:"global-lists-filters-columns"},_vm._l((_vm.columns),function(singleColumn){return _c('b-checkbox',{key:singleColumn.label,model:{value:(singleColumn.display),callback:function ($$v) {_vm.$set(singleColumn, "display", $$v)},expression:"singleColumn.display"}},[_vm._v(" "+_vm._s(singleColumn.label)+" ")])}),1),_c('div',{staticClass:"global-lists-filters-container"},[_c('b-field',[_c('b-field',{attrs:{"label":"Almacenes","expanded":"","label-position":"inside"}},[_c('b-taginput',{attrs:{"data":_vm.warehouses,"autocomplete":"","allow-new":false,"open-on-focus":true,"field":"NOMBRE_ALM","icon":"warehouse","ellipsis":"","placeholder":"Nombre de almacen","aria-close-label":"Borrar almacen seleccionado","check-infinite-scroll":""},on:{"add":function (value) { return _vm.changeFilterValue(); },"remove":function (value) { return _vm.changeFilterValue(); }},model:{value:(_vm.filterWarehouses),callback:function ($$v) {_vm.filterWarehouses=$$v},expression:"filterWarehouses"}})],1)],1)],1),_c('div',{staticClass:"global-lists-filters-container"},[_c('b-field',{attrs:{"label":"Cliente (Opcional)","expanded":"","label-position":"inside"}},[_c('b-autocomplete',{attrs:{"placeholder":"Cliente","data":_vm.autoCompleteClient(_vm.clientName),"field":"NOMBRE_EMPRESA","autocomplete":"","icon":"credit-card-outline","open-on-focus":"","expanded":"","clearable":"","check-infinite-scroll":""},on:{"select":function (option) {
              if (option) {
                _vm.filterClient = option._id;
                _vm.changeFilterValue();
              }
            },"input":function (input) {
              if (input === '') {
                _vm.filterClient = '';
                _vm.changeFilterValue();
              }
            }},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_vm._v("No se encontraron clientes")]},proxy:true}]),model:{value:(_vm.clientName),callback:function ($$v) {_vm.clientName=$$v},expression:"clientName"}})],1)],1),_c('div',{staticClass:"global-lists-filters-container"},[_c('b-field',{staticClass:"no-margin",attrs:{"label":"Fecha de inicio","label-position":"inside","type":{ 'is-danger': _vm.errors.startDate },"message":{
          'La fecha de inicio no es valida': _vm.errors.startDate,
        }}},[_c('b-datepicker',{staticClass:"global-lists-filters-control",attrs:{"date-formatter":function (date) { return _vm.moment(date).format('DD/MM/YYYY'); },"day-names":['D', 'L', 'M', 'M', 'J', 'V', 'S'],"month-names":[
            'Enero',
            'Febrero',
            'Marzo',
            'Abril',
            'Mayo',
            'Junio',
            'Julio',
            'Agosto',
            'Septiembre',
            'Octubre',
            'Noviembre',
            'Diciembre' ],"show-week-number":true,"locale":"es-MX","placeholder":"Fecha de inicio","icon":"calendar-today","trap-focus":""},on:{"input":function (value) { return _vm.changeFilterValue(value); }},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1),_c('b-field',{staticClass:"no-margin",attrs:{"label":"Fecha de fin","label-position":"inside","type":{ 'is-danger': _vm.errors.endDate },"message":{
          'La fecha de fin no es valida': _vm.errors.endDate,
        }}},[_c('b-datepicker',{staticClass:"global-lists-filters-control",attrs:{"date-formatter":function (date) { return _vm.moment(date).format('DD/MM/YYYY'); },"day-names":['D', 'L', 'M', 'M', 'J', 'V', 'S'],"month-names":[
            'Enero',
            'Febrero',
            'Marzo',
            'Abril',
            'Mayo',
            'Junio',
            'Julio',
            'Agosto',
            'Septiembre',
            'Octubre',
            'Noviembre',
            'Diciembre' ],"show-week-number":true,"locale":"es-MX","placeholder":"Fecha de fin","icon":"calendar-today","trap-focus":""},on:{"input":function (value) { return _vm.changeFilterValue(value); }},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1)],1)]),_c('div',{staticClass:"global-list-main-container"},[_c('div',{staticClass:"table-header"},[_vm._v(" Cantidad de ventas: "),_c('b',[_vm._v(_vm._s(_vm.sales.length))])]),_c('b-table',{ref:"sales",attrs:{"pagination-position":"both","data":_vm.sales,"striped":true,"bordered":true,"hoverable":true,"scrollable":"","checked-rows":_vm.checkedSales,"paginated":"","current-page":_vm.currentPage,"per-page":_vm.perPage,"opened-detailed":_vm.defaultOpenedDetails,"selected":_vm.selected,"detailed":"","detail-key":"_id","sort-icon":"arrow-up","sort-icon-size":"is-small","default-sort-direction":"desc","checkbox-position":"left","default-sort":"CLAVE_VENTA"},on:{"update:checkedRows":function($event){_vm.checkedSales=$event},"update:checked-rows":function($event){_vm.checkedSales=$event},"update:selected":function($event){_vm.selected=$event},"details-open":function (row) { return _vm.$buefy.toast.open({
            message: ("Información de " + (row.CLAVE_VENTA)),
            type: 'is-success',
            position: 'is-bottom',
          }); }},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"has-text-centered"},[_vm._v("No hay ventas")])]},proxy:true},{key:"default",fn:function(props){return _vm._l((_vm.columns),function(column){return _c('b-table-column',_vm._b({key:column.field,attrs:{"searchable":"","sortable":"","visible":column.display,"label":column.label,"custom-sort":column.customSort,"custom-search":column.customSearch}},'b-table-column',column,false),[(!_vm.checkIfValid(props.row[column.field]))?_c('span',[_vm._v(_vm._s("Sin información"))]):_vm._e(),(column.money && _vm.checkIfValid(props.row[column.field]))?_c('span',[_vm._v(_vm._s(_vm._f("money")(props.row[column.field],"MXN", 2)))]):_vm._e(),(!column.money && _vm.checkIfValid(props.row[column.field]))?_c('span',[_vm._v(_vm._s(props.row[column.field]))]):_vm._e()])})}},{key:"detail",fn:function(props){return [_c('article',{staticClass:"media"},[_c('figure',{staticClass:"media-left"},[_c('p',{staticClass:"image is-64x64"})]),_c('div',{staticClass:"media-content"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"controls"},[(
                    (props.row.STATUS_FAC === 'SF' ||
                      props.row.STATUS_FAC === 'PP') &&
                    props.row.STATUS !== 'CA'
                  )?_c('b-button',{attrs:{"type":"is-primary"},on:{"click":function($event){return _vm.showSale(props.row)}}},[_vm._v("Ver o timbrar venta")]):_vm._e(),(
                    props.row.STATUS_FAC === 'TI' ||
                    props.row.STATUS_FAC === 'PF' ||
                    props.row.STATUS_FAC === 'CA' ||
                    props.row.STATUS_FAC === 'FD' ||
                    props.row.STATUS === 'CA'
                  )?_c('b-button',{attrs:{"type":"is-primary"},on:{"click":function($event){return _vm.showSale(props.row)}}},[_vm._v("Ver venta")]):_vm._e(),(
                    props.row.STATUS_FAC === 'TI' ||
                    props.row.STATUS_FAC === 'PF' ||
                    props.row.STATUS_FAC === 'CA'
                  )?_c('b-button',{attrs:{"type":"is-primary"},on:{"click":function($event){return _vm.showInvoices(props.row)}}},[_vm._v("Ver facturas")]):_vm._e(),_c('b-button',{attrs:{"type":"is-primary"},on:{"click":function($event){return _vm.showPurchaseOrders(props.row)}}},[_vm._v("Ver ordenes de compra")]),_c('b-button',{attrs:{"type":"is-primary"},on:{"click":function($event){return _vm.showMaterialDeliveries(props.row)}}},[_vm._v("Ver envios de material")]),_c('b-button',{attrs:{"type":"is-primary"},on:{"click":function($event){return _vm.openPrintSalesQuotesModal(props.row)}}},[_vm._v("Enviar o imprimir venta")]),_c('b-button',{attrs:{"type":"is-primary"},on:{"click":function($event){return _vm.openPrintModal(props.row)}}},[_vm._v("Imprimir o descargar ticket")]),(
                    props.row.STATUS !== 'CA' &&
                    props.row.STATUS_FAC !== 'TI' &&
                    props.row.STATUS_FAC !== 'PF' &&
                    _vm.checkCanDelete(props.row)
                  )?_c('b-button',{attrs:{"type":"is-primary"},on:{"click":function($event){return _vm.cancelationSaleDeliveriesPopup(props.row)}}},[_vm._v("Cancelar venta")]):_vm._e(),(
                    props.row.STATUS !== 'CA' &&
                    (props.row.STATUS_FAC === 'TI' ||
                      props.row.STATUS_FAC === 'PF') &&
                    _vm.checkCanDelete(props.row) &&
                    _vm.checkCanDeleteInvoice(props.row)
                  )?_c('b-button',{attrs:{"type":"is-primary"},on:{"click":function($event){return _vm.cancelationInvoiceDeliveriesPopup(props.row)}}},[_vm._v("Cancelar venta")]):_vm._e(),(
                    props.row.STATUS_FAC === 'SF' &&
                    _vm.companyPaymentPlanModules &&
                    _vm.companyPaymentPlanModules.sales.saveClarification ===
                      'S' &&
                    _vm.permissions &&
                    _vm.permissions.sales.saveClarification === 'S'
                  )?_c('b-button',{attrs:{"type":"is-primary"},on:{"click":function($event){return _vm.openClarificationDeliveriesPopup(props.row)}}},[_vm._v("Agregar aclaración")]):_vm._e(),_c('b-button',{attrs:{"type":"is-primary"},on:{"click":function($event){return _vm.showClarificationDetails(props.row)}}},[_vm._v("Ver aclaraciónes")]),(
                    !props.row.VENTA_PARCIAL_ORDEN &&
                    _vm.companyPaymentPlanModules &&
                    _vm.companyPaymentPlanModules.sales.editPartialSaleOrder ===
                      'S' &&
                    _vm.permissions &&
                    _vm.permissions.sales.editPartialSaleOrder === 'S'
                  )?_c('b-button',{attrs:{"type":"is-primary"},on:{"click":function($event){return _vm.allowPartialSaleOrderValue(props.row, true)}}},[_vm._v("Permitir uso parcial en ordenes de compra")]):_vm._e(),(
                    props.row.VENTA_PARCIAL_ORDEN &&
                    _vm.companyPaymentPlanModules &&
                    _vm.companyPaymentPlanModules.sales.editPartialSaleOrder ===
                      'S' &&
                    _vm.permissions &&
                    _vm.permissions.sales.editPartialSaleOrder === 'S'
                  )?_c('b-button',{attrs:{"type":"is-primary"},on:{"click":function($event){return _vm.allowPartialSaleOrderValue(props.row, false)}}},[_vm._v("Bloquear uso parcial en ordenes de compra")]):_vm._e(),(
                    !props.row.VENTA_PARCIAL &&
                    _vm.companyPaymentPlanModules &&
                    _vm.companyPaymentPlanModules.sales.editPartialSale === 'S' &&
                    _vm.permissions &&
                    _vm.permissions.sales.editPartialSale === 'S'
                  )?_c('b-button',{attrs:{"type":"is-primary"},on:{"click":function($event){return _vm.allowPartialSaleValue(props.row, true)}}},[_vm._v("Permitir uso parcial en envios de material")]):_vm._e(),(
                    props.row.VENTA_PARCIAL &&
                    _vm.companyPaymentPlanModules &&
                    _vm.companyPaymentPlanModules.sales.editPartialSale === 'S' &&
                    _vm.permissions &&
                    _vm.permissions.sales.editPartialSale === 'S'
                  )?_c('b-button',{attrs:{"type":"is-primary"},on:{"click":function($event){return _vm.allowPartialSaleValue(props.row, false)}}},[_vm._v("Bloquear uso parcial en envios de material")]):_vm._e(),(
                    _vm.companyPaymentPlanModules &&
                    _vm.companyPaymentPlanModules.sales.removeSaleBackOrder ===
                      'S' &&
                    _vm.permissions &&
                    _vm.permissions.sales.removeSaleBackOrder === 'S' &&
                    props.row.STATUS_FAC !== 'TI'
                  )?_c('b-button',{attrs:{"type":"is-primary"},on:{"click":function($event){return _vm.startRemoveSaleBackOrder(props.row, true)}}},[_vm._v("Cancelar material faltante de enviar")]):_vm._e()],1)])])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }