<template>
  <div class="container global-list-main-container">
    <div class="upper-global-title">
      <span class=""> Ventas </span>
    </div>
    <div class="upper-global-hint">
      <b-icon icon="lightbulb-outline" type="is-primary"> </b-icon>
      <span class="global-form-hint-color"> Recuerda: </span> {{ hint }}
    </div>
    <b-tabs type="is-boxed" v-model="activeTab" @input="tabClicked(activeTab)">
      <b-tab-item label="Cotizaciones" icon="file-account-outline">
        <QuotesList />
      </b-tab-item>
      <b-tab-item label="Ventas" icon="file-check-outline">
        <SalesList />
      </b-tab-item>
      <b-tab-item label="Artículos faltantes de envio" icon="file-send-outline">
        <BackOrderSalesList />
      </b-tab-item>
      <b-tab-item label="Métodos de pago" icon="credit-card">
        <PaymentTypesList />
      </b-tab-item>
      <b-tab-item label="Conceptos de descuento" icon="image-broken-variant">
        <DiscountConceptsList />
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import SalesList from "../components/SalesList";
import QuotesList from "../components/QuotesList";
import BackOrderSalesList from "../components/BackOrderSalesList";
import PaymentTypesList from "../components/PaymentTypesList";
import DiscountConceptsList from "../components/DiscountConceptsList.vue";

export default {
  name: "AllSalesList",
  components: {
    SalesList,
    QuotesList,
    BackOrderSalesList,
    DiscountConceptsList,
    PaymentTypesList,
  },
  data() {
    return {
      activeTab: 0,
    };
  },
  mounted() {
    this.$store.dispatch("CHANGEACTIVE", "sales");
  },
  async created() {
    let allPromises = [];
    allPromises.push(this.$store.dispatch("GETADVANCEDPAYMENTS"));
    allPromises.push(
      this.$store.dispatch("GETSALESWITHOUTSALES", {
        startDate: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() - 7
        ),
        endDate: new Date(),
        warehouses: [],
      })
    );
    allPromises.push(
      this.$store.dispatch("GETQUOTES", {
        startDate: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() - 7
        ),
        endDate: new Date(),
        warehouses: [],
      })
    );
    allPromises.push(this.$store.dispatch("GETDISCOUNTCONCEPTS"));
    allPromises.push(this.$store.dispatch("GETCOMMERCIALBUSINESS"));
    allPromises.push(this.$store.dispatch("GETSATCFDIUSES"));
    allPromises.push(this.$store.dispatch("GETPAYMENTTYPES"));
    allPromises.push(this.$store.dispatch("GETSATREGIMENES"));
    allPromises.push(this.$store.dispatch("GETPACKAGINGS"));
    allPromises.push(this.$store.dispatch("GETTRASNPORTATIONPERMISSIONS"));
    allPromises.push(this.$store.dispatch("GETVEHICULES"));
    allPromises.push(this.$store.dispatch("GETTRANSPORTATIONCONFIGURATION"));
    allPromises.push(this.$store.dispatch("GETTRAILERTYPE"));
    allPromises.push(this.$store.dispatch("GETWEIGHTUNITS"));
    allPromises.push(this.$store.dispatch("GETMEASURES"));
    allPromises.push(this.$store.dispatch("GETDANGEROUSMATERIALS"));
    allPromises.push(this.$store.dispatch("GETLASTCLARIFICATIONNUMBER"));
    allPromises.push(this.$store.dispatch("GETLASTCREDITNOTENUMBER"));
    allPromises.push(this.$store.dispatch("GETCOMPANYNAMES"));

    await Promise.all(allPromises);
  },
  methods: {
    async tabClicked(tabClicked) {
      if (tabClicked === 2) {
        let allPromises = [];

        allPromises.push(
          this.$store.dispatch("GETSALES", {
            startDate: new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate() - 7
            ),
            endDate: new Date(),
            warehouses: [],
          })
        );

        await Promise.all(allPromises);
      }
    },
  },
  computed: {
    hint() {
      return this.$store.getters.HINT;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
